<script>
import CockpitMatryoshkaInheritanceTreeSection
from '@/components/cockpit/matryoshka/inheritance-tree/CockpitMatryoshkaInheritanceTreeSection.vue';
import { mapActions, mapGetters } from 'vuex';
import MarkingsAssignList from '@/components/markings/assign-list/MarkingsAssignList.vue';
import MarkingsAssignListFilters
from '@/components/markings/assign-list/MarkingsAssignListFilters.vue';

export default {
  name: 'CockpitMatryoshkaInheritanceTree',
  props: {
    labelArchiveId: Number,
  },
  data: () => ({
    loadingDetails: true,
    itemDetails: {},
    parentItems: [],
    childrenItems: [],

    toAssignFilters: {
      searchQuery: '',
      orderId: null,
      lineId: null,
      status: null,
    },
    loadingToAssignItems: false,
    pendingToAssign: false,
    pendingToDetach: false,
    toAssignItems: [],
    toAssignItemsCurrentPage: 1,
    toAssignItemsPerPage: 10,
    toAssignItemsTotal: 0,
    toAssignType: 'parent',
    assignToType: {
      parent: 'parent',
      children: 'children',
    },

    showAssignModal: false,
  }),
  components: {
    MarkingsAssignListFilters,
    MarkingsAssignList,
    CockpitMatryoshkaInheritanceTreeSection,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    ...mapGetters('packingSettings', [
      'getHigherLevelUnit',
      'getLowerLevelUnit',
    ]),
    disabledItemIds() {
      return [
        ...this.parentItems.map(i => i.id),
        ...this.childrenItems.map(i => i.id),
      ];
    },
    fetchParams() {
      return {
        plantCode: this.globalSettings.plantCode,
        query: {
          serialNumber: this.toAssignFilters.searchQuery,
          orderId: this.toAssignFilters.orderId,
          labelStatus: this.toAssignFilters.status,
          lineId: this.toAssignFilters.lineId,
          skip: (this.toAssignItemsCurrentPage - 1) * this.toAssignItemsPerPage,
          take: this.toAssignItemsPerPage,
        },
      };
    },
    lowerLevelUnit() {
      return this.getLowerLevelUnit(
        this.itemDetails.unitId,
        this.itemDetails.skuId,
      );
    },
    upperLevelUnit() {
      return this.getHigherLevelUnit(
        this.itemDetails.unitId,
        this.itemDetails.skuId,
      );
    },
    canConnectToUpperLevel() {
      return !!this.upperLevelUnit?.id;
    },
    canConnectToLowerLevel() {
      return !!this.lowerLevelUnit?.id;
    },
  },
  watch: {
    labelArchiveId: {
      handler() {
        this.getDetails();
      },
      immediate: true,
    },
    toAssignFilters: {
      handler() {
        this.toAssignItemsCurrentPage = 1;
      },
      deep: true,
    },
    fetchParams: {
      handler() {
        this.fetchToAssignItems();
      },
      deep: true,
    },
    showAssignModal(v) {
      if (v) {
        this.fetchToAssignItems();
      } else {
        this.toAssignItems = [];
      }
    },
  },
  methods: {
    ...mapActions('labelArchive', [
      'getLabelArchive',
      'getLabelArchiveItem',
      'getLabelArchiveByContainerLabel',
      'getLabelArchiveByContainedLabel',
      'attachLabelArchiveItem',
      'detachLabelArchiveItem',
    ]),
    async fetchParents() {
      const { plantCode } = this.globalSettings;
      const { data } = await this.getLabelArchiveByContainedLabel({
        params: {
          plantCode,
          query: {
            id: this.labelArchiveId,
          },
        },
      });
      this.parentItems = data
        .filter(i => i.unitId === this.upperLevelUnit?.id);
    },
    async fetchChildren() {
      const { plantCode } = this.globalSettings;
      const { data } = await this.getLabelArchiveByContainerLabel({
        params: {
          plantCode,
          query: {
            id: this.labelArchiveId,
          },
        },
      });
      this.childrenItems = data
        .filter(i => i.unitId === this.lowerLevelUnit?.id);
    },
    async fetchLabelArchiveItem() {
      if (!this.labelArchiveId) return;

      const { plantCode } = this.globalSettings;
      const { data } = await this.getLabelArchiveItem({
        params: {
          id: this.labelArchiveId,
          plantCode,
        },
      });
      this.itemDetails = { ...data };
    },
    async fetchToAssignItems() {
      if (!this.showAssignModal) return;

      const { plantCode } = this.globalSettings;
      this.loadingToAssignItems = true;

      let unitId = null;
      if (this.toAssignType === this.assignToType.parent) {
        unitId = this.getHigherLevelUnit(
          this.itemDetails.unitId,
          this.itemDetails.skuId,
        )?.id;
      } else {
        unitId = this.getLowerLevelUnit(
          this.itemDetails.unitId,
          this.itemDetails.skuId,
        )?.id;
      }

      const { data } = await this.getLabelArchive({
        params: {
          plantCode,
          query: {
            serialNumber: this.toAssignFilters.searchQuery,
            orderId: this.toAssignFilters.orderId,
            labelStatus: this.toAssignFilters.status,
            lineId: this.toAssignFilters.lineId,
            skuId: this.itemDetails.skuId,
            unitId,
            skip: (this.toAssignItemsCurrentPage - 1) * this.toAssignItemsPerPage,
            take: this.toAssignItemsPerPage,
          },
        },
      });
      this.toAssignItems = data
        .items
        .filter(i => i.id !== this.labelArchiveId);
      this.toAssignItemsTotal = data?.totalCount || 0;
      this.loadingToAssignItems = false;
    },
    async getDetails() {
      this.loadingDetails = true;

      await this.fetchLabelArchiveItem();
      await this.fetchParents();
      await this.fetchChildren();

      this.loadingDetails = false;
    },
    handleConnectNewParent() {
      this.toAssignType = this.assignToType.parent;
      this.showAssignModal = true;
    },
    handleConnectNewChildren() {
      this.toAssignType = this.assignToType.children;
      this.showAssignModal = true;
    },
    async handleDisconnectParent(id) {
      const { plantCode } = this.globalSettings;
      this.pendingToDetach = true;
      await this.detachLabelArchiveItem({
        params: {
          plantCode,
          id: this.labelArchiveId,
        },
        data: {
          destId: id,
        },
      });
      await this.getDetails();
      this.pendingToDetach = false;
    },
    async handleDisconnectChildren(id) {
      const { plantCode } = this.globalSettings;
      this.pendingToDetach = true;
      await this.detachLabelArchiveItem({
        params: {
          plantCode,
          id,
        },
        data: {
          destId: this.labelArchiveId,
        },
      });
      await this.getDetails();
      this.pendingToDetach = false;
    },
    async handleSelectConnectItem(id) {
      if (!this.showAssignModal) return;

      const { plantCode } = this.globalSettings;
      this.pendingToAssign = true;
      switch (this.toAssignType) {
        case this.assignToType.parent:
          await this.attachLabelArchiveItem({
            params: {
              plantCode,
              id: this.labelArchiveId,
            },
            data: {
              destId: id,
            },
          });
          break;
        case this.assignToType.children:
          await this.attachLabelArchiveItem({
            params: {
              plantCode,
              id,
            },
            data: {
              destId: this.labelArchiveId,
            },
          });
          break;
        default:
          break;
      }
      await this.getDetails();
      this.pendingToAssign = false;
      this.showAssignModal = false;
    },
  },
};
</script>

<template>
  <div>
    <Loader v-if="loadingDetails" />

    <BOverlay :show="pendingToDetach">
      <CockpitMatryoshkaInheritanceTreeSection
        v-if="!loadingDetails && upperLevelUnit"
        label="Rodzic"
        :items="parentItems"
        upper-level
        :show-connect-button="canConnectToUpperLevel"
        @connect="handleConnectNewParent"
        @disconnect="handleDisconnectParent"
      />

      <CockpitMatryoshkaInheritanceTreeSection
        v-if="!loadingDetails && lowerLevelUnit"
        label="Dzieci"
        :items="childrenItems"
        lower-level
        :show-connect-button="canConnectToLowerLevel"
        @connect="handleConnectNewChildren"
        @disconnect="handleDisconnectChildren"
      />
    </BOverlay>

    <BModal
      v-model="showAssignModal"
      hide-footer
      title="Wybierz element do połączenia"
      size="xl"
    >
      <BOverlay :show="pendingToAssign">
        <div class="to-assign-filters-wrapper">
          <MarkingsAssignListFilters
            v-model="toAssignFilters"
          />
        </div>

        <div class="to-assign-list-wrapper">
          <Loader
            v-if="loadingToAssignItems"
          />

          <MarkingsAssignList
            v-else
            :list="toAssignItems"
            :disabled-ids="disabledItemIds"
            @select-item="handleSelectConnectItem"
          />
        </div>

        <div class="to-assign-pagination-box">
          <BPagination
            v-model="toAssignItemsCurrentPage"
            :per-page="toAssignItemsPerPage"
            :total-rows="toAssignItemsTotal"
            size="sm"
            pills
            limit="5"
            first-number
            last-number
          />
        </div>
      </BOverlay>
    </BModal>
  </div>
</template>

<style scoped lang="scss">
.to-assign-filters-wrapper {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ECECEC;
}

.to-assign-list-wrapper {
  height: 70vh;
  overflow-y: auto;
  margin: 1rem 0;
}

.to-assign-pagination-box {
  display: flex;
  justify-content: center;
  border-top: 1px solid #ECECEC;
  padding-top: 1rem;
}
</style>
