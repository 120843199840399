<script>
import CockpitLineVerticalSelector
from '@/components/cockpit/lineSelector/CockpitLineVerticalSelector.vue';
import CockpitActiveOrderCard from '@/components/cockpit/activeOrder/CockpitActiveOrderCard.vue';
import CockpitMatryoshka from '@/components/cockpit/matryoshka/CockpitMatryoshka.vue';
import CockpitOffcanvas from '@/components/cockpit/CockpitOffcanvas.vue';
import CockpitActiveOrderDetailsTab
from '@/components/cockpit/activeOrder/offcanvas/tabs/CockpitActiveOrderDetailsTab.vue';
import CockpitActiveOrderActionButtons
from '@/components/cockpit/activeOrder/offcanvas/CockpitActiveOrderActionButtons.vue';
import CockpitActiveOrderHistoryTab
from '@/components/cockpit/activeOrder/offcanvas/tabs/CockpitActiveOrderHistoryTab.vue';
import CockpitMatryoshkaItemActionButtons
from '@/components/cockpit/matryoshka/offcanvas/CockpitMatryoshkaItemActionButtons.vue';
import CockpitMatryoshkaItemDetailsTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemDetailsTab.vue';
import CockpitMatryoshkaItemContentsTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemContentsTab.vue';
import CockpitMatryoshkaItemHistoryTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemHistoryTab.vue';
import CockpitMatryoshkaItemLabelTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemLabelTab.vue';
import CockpitMatryoshkaPrintManuallyTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaPrintManuallyTab.vue';
import CockpitOffcanvasTypes from '@/components/cockpit/CockpitOffcanvasTypes';
import CockpitMatryoshkaItemAuditTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemAuditTab.vue';
import CockpitMatryoshkaItemOperationsTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemOperationsTab.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Cockpit',
  data: () => ({
    initLoading: false,
    linesCollapsed: false,
    selectedLineId: 0,
    selectedLabelArchiveId: 0,
    offcanvasTabIndex: 0,
    offcanvasVisible: false,
    offcanvasData: {
      orderId: 1,
    },
    CockpitOffcanvasTypes,
    loadingActiveLineOrder: false,
    activeLineOrder: null,
  }),
  components: {
    CockpitMatryoshkaItemOperationsTab,
    CockpitMatryoshkaItemAuditTab,
    CockpitMatryoshkaPrintManuallyTab,
    CockpitMatryoshkaItemLabelTab,
    CockpitMatryoshkaItemHistoryTab,
    CockpitMatryoshkaItemContentsTab,
    CockpitMatryoshkaItemDetailsTab,
    CockpitMatryoshkaItemActionButtons,
    CockpitActiveOrderHistoryTab,
    CockpitActiveOrderActionButtons,
    CockpitActiveOrderDetailsTab,
    CockpitOffcanvas,
    CockpitMatryoshka,
    CockpitActiveOrderCard,
    CockpitLineVerticalSelector,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    offcanvasTitle() {
      switch (this.offcanvasVisible) {
        case CockpitOffcanvasTypes.ORDER_DETAILS:
          return 'Szczegóły zlecenia';
        case CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS:
          return 'Szczegóły';
        case CockpitOffcanvasTypes.PRINT_MANUALLY:
          return 'Drukuj ręczne';
        case CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT:
          return 'Audyt';
        case CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS:
          return 'Dostępne operacje';
        default:
          return '';
      }
    },
    offcanvasIcon() {
      switch (this.offcanvasVisible) {
        case CockpitOffcanvasTypes.PRINT_MANUALLY:
          return 'fa-print';
        case CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT:
          return 'fa-clock';
        default:
          return 'fa-info-circle';
      }
    },
    offcanvasTabs() {
      switch (this.offcanvasVisible) {
        case CockpitOffcanvasTypes.ORDER_DETAILS:
          return [
            'Ogólne',
            'Historia',
          ];
        case CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS:
          return [
            'Ogólne',
            'Zawartość',
            'Historia',
            'Etykieta',
          ];
        case CockpitOffcanvasTypes.PRINT_MANUALLY:
          return [
            'Wydruk',
          ];
        case CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT:
          return [
            'Audyt',
          ];
        case CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS:
          return [
            'Operacje',
          ];
        default:
          return [];
      }
    },
  },
  watch: {
    offcanvasVisible: {
      handler() {
        this.offcanvasData = {};
        this.offcanvasTabIndex = 0;
      },
    },
    selectedLineId: {
      handler() {
        this.fetchProdOrderForSelectedLine();
      },
    },
  },
  methods: {
    ...mapActions([
      'getProdOrders',
    ]),
    ...mapActions('packingSettings', [
      'getUnits',
      'getPackingSettings',
    ]),
    async fetchProdOrderForSelectedLine() {
      this.loadingActiveLineOrder = true;
      const { data } = await this.getProdOrders({
        params: {
          query: {
            lineId: this.selectedLineId,
          },
        },
      });
      this.activeLineOrder = data[0];
      this.loadingActiveLineOrder = false;
    },
    handleSelectOrder() {
      this.offcanvasVisible = CockpitOffcanvasTypes.ORDER_DETAILS;
    },
    handleSelectQueueItem(id) {
      this.selectedLabelArchiveId = id;
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS;
    },
    handleSelectPrintManually() {
      this.offcanvasVisible = CockpitOffcanvasTypes.PRINT_MANUALLY;
    },
    handleAuditButtonClicked() {
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT;
    },
    handleBackButtonClicked() {
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS;
    },
    handleOpenOperations() {
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS;
    },
  },
  async created() {
    const { plantCode } = this.globalSettings;
    this.initLoading = true;
    await this.getUnits({
      params: {
        plantCode,
      },
    });
    await this.getPackingSettings({
      params: {
        plantCode,
      },
    });
    this.initLoading = false;
  },
};
</script>

<template>
  <Loader v-if="initLoading" />
  <div v-else>
    <div class="cockpit-root">
      <CockpitLineVerticalSelector
        v-model="selectedLineId"
        :lines-collapsed.sync="linesCollapsed"
      />

      <div class="cockpit-wrapper">
        <div
          v-if="loadingActiveLineOrder"
          class="loading"
        >
          <Loader />
        </div>

        <div
          v-if="!loadingActiveLineOrder && !activeLineOrder"
          class="no-active-order-info"
        >
          <i class="fas fa-info-circle" />
          Brak aktywnego zlecenia
        </div>

        <CockpitActiveOrderCard
          v-if="activeLineOrder && !loadingActiveLineOrder"
          :active-order="activeLineOrder"
          @select-order="handleSelectOrder"
        />

        <CockpitMatryoshka
          v-if="activeLineOrder && !loadingActiveLineOrder"
          :selected-line-id="selectedLineId"
          :active-order="activeLineOrder"
          :lines-collapsed="linesCollapsed"
          @select-item="handleSelectQueueItem"
          @print-manually="handleSelectPrintManually"
        />
      </div>
    </div>

    <CockpitOffcanvas
      :show="!!offcanvasVisible"
      :tabs-key="offcanvasVisible || ''"
      :tab-index.sync="offcanvasTabIndex"
      :tabs="offcanvasTabs"
      :title="offcanvasTitle"
      :icon="offcanvasIcon"
      :show-audit-button="
        offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS"
      :show-back-button="
        offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
          || offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT
      "
      @audit-button-clicked="handleAuditButtonClicked"
      @back-button-clicked="handleBackButtonClicked"
      @update:show="offcanvasVisible = $event"
    >
      <template #default>
        <!--    ACTIVE ORDER    -->
        <CockpitActiveOrderDetailsTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.ORDER_DETAILS
              && offcanvasTabIndex === 0
          "
          :active-order="activeLineOrder"
        />
        <CockpitActiveOrderHistoryTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.ORDER_DETAILS
              && offcanvasTabIndex === 1
          "
          :active-order="activeLineOrder"
        />

        <!--    MATRIOSZKA MANUAL PRINT    -->
        <CockpitMatryoshkaPrintManuallyTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.PRINT_MANUALLY
              && offcanvasTabIndex === 0
          "
        />

        <!--    MATRIOSZKA    -->
        <CockpitMatryoshkaItemDetailsTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 0
          "
          :label-archive-id="selectedLabelArchiveId"
        />
        <CockpitMatryoshkaItemContentsTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 1
          "
          :label-archive-id="selectedLabelArchiveId"
        />
        <CockpitMatryoshkaItemHistoryTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 2
          "
          :label-archive-id="selectedLabelArchiveId"
        />
        <CockpitMatryoshkaItemLabelTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 3
          "
          :label-archive-id="selectedLabelArchiveId"
        />

        <!--    MATRIOSZKA OPERATIONS    -->
        <CockpitMatryoshkaItemOperationsTab
          v-if="offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
            && offcanvasTabIndex === 0"
        />

        <!--    MATRIOSZKA AUDIT    -->
        <CockpitMatryoshkaItemAuditTab
          v-if="offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT
            && offcanvasTabIndex === 0"
        />
      </template>

      <template #actions>
        <!--    ACTIVE ORDER    -->
        <CockpitActiveOrderActionButtons
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.ORDER_DETAILS
              && offcanvasTabIndex === 0
          "
        />

        <!--    MATRIOSZKA    -->
        <CockpitMatryoshkaItemActionButtons
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 0
          "
          @open-operations="handleOpenOperations"
        />
      </template>
    </CockpitOffcanvas>
  </div>
</template>

<style scoped lang="scss">
.cockpit-root {
  display: flex;
  position: relative;
  height: calc(100vh - 60px + 1rem);
  width: calc(100% + 3rem);
  margin: -1rem -1.5rem;
  overflow-y: auto;

  .cockpit-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    background-color: #F6F6F6;
    border-right: 1px solid #ECECEC;
    padding: 1.5rem 1rem 1rem 1rem;
    gap: 12px;
  }

  .no-active-order-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 24px;
    font-weight: 500;
    color: #888888;

    i {
      margin-right: 8px;
    }
  }
}
</style>
