import Vue from 'vue';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import store from '@/store';
import {
  LOGICPRINT5_LABELLING,
  LOGICPRINT5_LABELS,
  LOGICPRINT5_ORDERS,
  LOGICPRINT5_REQUESTS,
  LOGICPRINT5_SETTINGS,
  LOGICPRINT5_SKU,
} from '@/utils/permissionsDictionary';
import NoAccess from '@/pages/auth/NoAccess';
import Sku from '@/pages/Sku';
import LabelsMapper from '@/pages/LabelsMapper';
import Orders from '@/pages/Orders';
import Requests from '@/pages/Requests';
import Dashboard from '@/pages/Dashboard';
import Labelling from '@/pages/Labelling';
import OidcCallback from '@/pages/auth/OidcCallback';
import SettingsIndex from '@/pages/settings/Index';
import Printers from '@/components/settings/printers/Printers';
import SkuFieldsList from '@/components/sku/SkuFieldsList';
import LabelingPoints from '@/components/settings/labelingPoints/LabelingPoints';
import Mappings from '@/components/settings/mappings/Mappings';
import Lines from '@/components/settings/lines/Lines';
import Areas from '@/components/settings/areas/Areas';
import SetupIssues from '@/components/settings/SetupIssues';
import GeneralSettings from '@/components/settings/general/GeneralSettings';
import OrderFields from '@/components/settings/orderFields/OrderFields';
import DictionariesSettings from '@/components/settings/dictionaries/DictionariesSettings';
import Cockpit from '@/pages/Cockpit.vue';
import Markings from '@/pages/Markings.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/authentication/login-callback',
    component: OidcCallback,
  },
  {
    path: '/',
    component: Dashboard,
    children: [
      {
        path: '/',
        component: Labelling,
        meta: {
          permittedFor: [LOGICPRINT5_LABELLING],
        },
      },
      {
        path: '/cockpit',
        component: Cockpit,
        meta: {
          permittedFor: [LOGICPRINT5_SETTINGS],
        },
      },
      {
        path: '/markings',
        component: Markings,
        meta: {
          permittedFor: [LOGICPRINT5_SETTINGS],
        },
      },
      {
        path: '/orders',
        component: Orders,
        meta: {
          permittedFor: [LOGICPRINT5_ORDERS],
        },
      },
      {
        path: '/sku',
        component: Sku,
        meta: {
          permittedFor: [LOGICPRINT5_SKU],
        },
      },
      {
        path: '/labelsMapper',
        component: LabelsMapper,
        meta: {
          permittedFor: [LOGICPRINT5_LABELS],
        },
      },
      {
        path: '/requests',
        component: Requests,
        meta: {
          permittedFor: [LOGICPRINT5_REQUESTS],
        },
      },
      {
        path: '/settings',
        component: SettingsIndex,
        name: 'settings',
        props: true,
        meta: {
          permittedFor: [LOGICPRINT5_SETTINGS],
        },
        children: [
          {
            path: 'plant/generalSettings',
            component: GeneralSettings,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
          {
            path: 'config/areas',
            component: Areas,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
          {
            path: 'config/lines',
            component: Lines,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
          {
            path: 'config/printers',
            component: Printers,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
          {
            path: 'config/labelingpoints',
            component: LabelingPoints,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
          {
            path: 'config/mappings',
            component: Mappings,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
          {
            path: 'order/fields',
            component: OrderFields,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
          {
            path: 'order/skuFields',
            component: SkuFieldsList,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
          {
            path: 'order/dictionaries',
            component: DictionariesSettings,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
          {
            path: 'setupIssues',
            component: SetupIssues,
            props: true,
            meta: {
              permittedFor: [LOGICPRINT5_SETTINGS],
            },
          },
        ],
      },
      {
        path: '/noAccess',
        component: NoAccess,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

export default router;
