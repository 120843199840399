<script>

import MarkingsListItem from '@/components/markings/list/MarkingsListItem.vue';

export default {
  name: 'MarkingsAssignListItem',
  props: {
    item: Object,
    disabled: Boolean,
  },
  components: {
    MarkingsListItem,
  },
  emits: [
    'select-item',
  ],
};
</script>

<template>
  <MarkingsListItem
    hide-actions
    :item="item"
    :disabled="disabled"
    @select-item="$emit('select-item', $event)"
  />
</template>

<style scoped lang="scss">
</style>
