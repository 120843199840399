var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.initLoading)?_c('Loader'):_c('div',[_c('div',{staticClass:"markings-root"},[_c('MarkingsTypeSelectButtons',{model:{value:(_vm.selectedUnitId),callback:function ($$v) {_vm.selectedUnitId=$$v},expression:"selectedUnitId"}}),_c('div',{staticClass:"markings-card"},[_c('MarkingsFilters',{model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}}),_c('div',{staticClass:"line"}),_c('div',{staticClass:"list"},[(_vm.pendingMarkingList)?_c('Loader',{staticClass:"pt-5"}):_c('MarkingsList',{attrs:{"list":_vm.markingsList},on:{"select-item":_vm.handleSelectItem}})],1),_c('div',{staticClass:"list-pagination"},[_c('BPagination',{attrs:{"per-page":_vm.perPage,"total-rows":_vm.totalRows,"size":"sm","pills":"","limit":"5","first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('CockpitOffcanvas',{attrs:{"show":!!_vm.offcanvasVisible,"tabs-key":_vm.offcanvasVisible || '',"tab-index":_vm.offcanvasTabIndex,"tabs":_vm.offcanvasTabs,"title":_vm.offcanvasTitle,"icon":_vm.offcanvasIcon,"show-audit-button":_vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS,"show-back-button":_vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
        || _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT},on:{"update:tabIndex":function($event){_vm.offcanvasTabIndex=$event},"update:tab-index":function($event){_vm.offcanvasTabIndex=$event},"audit-button-clicked":_vm.handleAuditButtonClicked,"back-button-clicked":_vm.handleBackButtonClicked,"update:show":function($event){_vm.offcanvasVisible = $event}},scopedSlots:_vm._u([{key:"default",fn:function(){return [(
          _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
            && _vm.offcanvasTabIndex === 0
        )?_c('CockpitMatryoshkaItemDetailsTab',{attrs:{"label-archive-id":_vm.selectedLabelArchiveId}}):_vm._e(),(
          _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
            && _vm.offcanvasTabIndex === 1
        )?_c('CockpitMatryoshkaItemContentsTab',{attrs:{"label-archive-id":_vm.selectedLabelArchiveId}}):_vm._e(),(
          _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
            && _vm.offcanvasTabIndex === 2
        )?_c('CockpitMatryoshkaItemHistoryTab',{attrs:{"label-archive-id":_vm.selectedLabelArchiveId}}):_vm._e(),(
          _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
            && _vm.offcanvasTabIndex === 3
        )?_c('CockpitMatryoshkaItemLabelTab',{attrs:{"label-archive-id":_vm.selectedLabelArchiveId}}):_vm._e(),(_vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
          && _vm.offcanvasTabIndex === 0)?_c('CockpitMatryoshkaItemOperationsTab'):_vm._e(),(_vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT
          && _vm.offcanvasTabIndex === 0)?_c('CockpitMatryoshkaItemAuditTab'):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [(
          _vm.offcanvasVisible === _vm.CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
            && _vm.offcanvasTabIndex === 0
        )?_c('CockpitMatryoshkaItemActionButtons',{on:{"open-operations":_vm.handleOpenOperations}}):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }