<script>
export default {
  name: 'CockpitMatryoshkaInheritanceTreeSectonItem',
  props: {
    item: Object,
    levelIcon: String,
  },
  emits: [
    'disconnect',
  ],
};
</script>

<template>
  <div>
    <div class="tree-section-item">
      <div class="level-icon">
        <i :class="levelIcon" />
      </div>

      <div class="inner">
        <div class="title">
          {{ item.serialNumber }}
        </div>

        <div
          class="unlink-button"
          @click="$emit('disconnect')"
        >
          <i class="fas fa-unlink" />
          Odłącz
        </div>

        <div class="in-button">
          <i class="fas fa-chevron-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.tree-section-item {
  position: relative;

  .level-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #B0B0B0;
  }

  .inner {
    width: 100%;
    border: 1px solid #F0F1F3;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    color: black;
    display: flex;
    padding: 12px 22px;
    margin-left: 30px;

    .unlink-button {
      margin-left: auto;
      padding: 2px 22px;
      font-size: 12px;
      border-radius: 50px;
      background-color: #EFEFEF;
      color: #7F7F7F;
      cursor: pointer;

      &:hover {
        background-color: #D9D9D9;
      }
    }

    .in-button {
      margin-left: 12px;
      color: 7E7E7E;
      font-size: 14px;
      line-height: 0;
      padding: 2px 10px;
      cursor: pointer;
      border-radius: 40px;

      i {
        transform: translateY(1.5px);
      }

      &:hover {
        background-color: #F0F1F3;
      }
    }
  }
}
</style>
