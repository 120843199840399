<script>
import CockpitMatryoshkaColumn from '@/components/cockpit/matryoshka/CockpitMatryoshkaColumn.vue';
import { mapGetters, mapState } from 'vuex';
import capitalize from '@/utils/capitalize';

export default {
  name: 'CockpitMatryoshka',
  props: {
    selectedLineId: Number,
    linesCollapsed: Boolean,
    activeOrder: Object,
  },
  emits: [
    'select-item',
    'print-manually',
  ],
  components: {
    CockpitMatryoshkaColumn,
  },
  computed: {
    ...mapState({
      packingSettings: state => state.packingSettings.packingSettings,
    }),
    ...mapGetters('packingSettings', [
      'getUnitsForSku',
    ]),
    columns() {
      if (!this.activeOrder) return [];
      return this.getUnitsForSku(this.activeOrder.order.skuId).map(unit => ({
        title: capitalize(unit.name),
        queueLength: Math.ceil(Math.random() * 10),
        palletsColumn: unit.name === 'paleta',
        unit,
      }));
    },
  },
};
</script>

<template>
  <div class="cockpit-matryoshka">
    <div
      class="cockpit-matryoshka-columns"
      :class="{
        'lines-collapsed': linesCollapsed,
      }"
    >
      <CockpitMatryoshkaColumn
        v-for="column in columns"
        :key="column.title"
        :title="column.title"
        :active-order="activeOrder"
        :unit="column.unit"
        @select-item="$emit('select-item', $event)"
        @print-manually="$emit('print-manually', $event)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.cockpit-matryoshka {
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  .cockpit-matryoshka-columns {
    height: 100%;
    width: calc(100vw - 192px);
    display: flex;
    gap: 12px;
    overflow-y: auto;

    &.lines-collapsed {
      width: calc(100vw - 129px);
    }
  }
}
</style>
