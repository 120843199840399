<script>

import CockpitStatusBadge from '@/components/cockpit/CockpitStatusBadge.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import MarkingStatusBadge from '@/components/markings/MarkingStatusBadge.vue';

export default {
  name: 'CockpitMatryoshkaItemDetailsTab',
  props: {
    labelArchiveId: Number,
  },
  data: () => ({
    loadingDetails: false,
    itemDetails: {},
    skuDetails: {},

    editForm: {},
    showEditForm: false,
    savePending: false,
  }),
  components: {
    MarkingStatusBadge,
    CockpitStatusBadge,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    ...mapState({
      lines: state => state.lines.list || [],
      labelingPoints: state => state.labelingPoints.list || [],
    }),
    lineName() {
      return this.lines.find(l => l.id === this.itemDetails.lineId)?.name || '';
    },
    labelingPointName() {
      return this.labelingPoints.find(l => l.id === this.itemDetails.labelingPointId)?.name || '';
    },
  },
  watch: {
    labelArchiveId: {
      handler() {
        this.getDetails();
      },
      immediate: true,
    },
    showEditForm(v) {
      if (!v) {
        this.editForm = {
          ...this.itemDetails,
        };
      }
    },
  },
  methods: {
    ...mapActions('labelArchive', [
      'getLabelArchiveItem',
      'updateLabelArchiveItem',
    ]),
    ...mapActions([
      'getSku',
    ]),
    async fetchSku() {
      if (!this.labelArchiveId) return;

      const { data } = await this.getSku({
        params: {
          skuId: this.itemDetails.skuId,
        },
      });
      this.skuDetails = data;
    },
    async fetchLabelArchiveItem() {
      if (!this.labelArchiveId) return;

      const { plantCode } = this.globalSettings;
      const { data } = await this.getLabelArchiveItem({
        params: {
          id: this.labelArchiveId,
          plantCode,
        },
      });
      this.editForm = { ...data };
      this.itemDetails = { ...data };
    },
    async getDetails() {
      this.loadingDetails = true;

      await this.fetchLabelArchiveItem();
      await this.fetchSku();

      this.loadingDetails = false;
    },
    async handleSave() {
      this.savePending = true;

      await this.updateLabelArchiveItem({
        params: {
          id: this.labelArchiveId,
          plantCode: this.globalSettings.plantCode,
        },
        data: {
          ...this.editForm,
        },
      });

      await this.getDetails();

      this.savePending = false;
      this.showEditForm = false;
    },
  },
};
</script>

<template>
  <div>
    <Loader
      v-if="loadingDetails"
    />

    <div
      v-else
      class="position-relative"
    >
      <div
        class="edit-button"
        @click="showEditForm = !showEditForm"
      >
        <i
          class="fas"
          :class="{
            'fa-pencil': !showEditForm,
            'fa-times': showEditForm,
          }"
        />

        <span v-if="!showEditForm">
          Edytuj
        </span>
        <span v-if="showEditForm">
          Anuluj
        </span>
      </div>

      <div class="info-row">
        <div class="label">
          ID
        </div>
        <div class="value">
          <span v-if="!showEditForm">
            {{ itemDetails.serialNumber }}
          </span>

          <BInput
            v-if="showEditForm"
            v-model="editForm.serialNumber"
            disabled
          />
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          ID zlecenia
        </div>
        <div class="value">
          <span v-if="!showEditForm">
            {{ itemDetails.orderId }}
          </span>

          <BInput
            v-if="showEditForm"
            v-model="editForm.orderId"
          />
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Batch ID
        </div>
        <div class="value">
          <span v-if="!showEditForm">
            {{ itemDetails.batchId }}
          </span>

          <BInput
            v-if="showEditForm"
            v-model="editForm.batchId"
          />
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          ID SKU
        </div>
        <div class="value">
          <span v-if="!showEditForm">
            {{ itemDetails.skuId }}
          </span>

          <BInput
            v-if="showEditForm"
            v-model="editForm.skuId"
          />
        </div>
      </div>

      <div
        v-if="!showEditForm"
        class="info-row"
      >
        <div class="label">
          Opis SKU
        </div>
        <div class="value">
          {{ skuDetails.name }}
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Linia
        </div>
        <div class="value">
          <span v-if="!showEditForm">
            {{ lineName }}
          </span>

          <BSelect
            v-if="showEditForm"
            v-model="editForm.lineId"
            :options="lines"
            value-field="id"
            text-field="name"
          />
        </div>
      </div>

      <div class="info-row">
        <div class="label">
          Punkt etykietowania
        </div>
        <div class="value">
          <span v-if="!showEditForm">
            {{ labelingPointName }}
          </span>

          <BSelect
            v-if="showEditForm"
            v-model="editForm.labelingPointId"
            :options="labelingPoints"
            value-field="id"
            text-field="name"
          />
        </div>
      </div>

      <div
        v-if="!showEditForm"
        class="info-row"
      >
        <div class="label">
          Ograniczenie
        </div>
        <div class="value">
          ?
        </div>
      </div>

      <div
        v-if="!showEditForm"
        class="info-row"
      >
        <div class="label">
          Status
        </div>
        <div class="value">
          <MarkingStatusBadge
            :status="itemDetails.labelStatus"
          />
        </div>
      </div>

      <div class="line" />

      <div
        v-if="showEditForm"
        class="action-buttons-container"
      >
        <BButton
          variant="primary"
          class="action-button"
          :disabled="savePending"
          @click="handleSave"
        >
          <i
            v-if="savePending"
            class="fas fa-spinner fa-spin"
          />
          <i
            v-else
            class="fas fa-save"
          />
          Zapisz
        </BButton>

        <BButton
          class="action-button"
          :disabled="savePending"
          @click="showEditForm = false"
        >
          <i class="fas fa-times" />
          Anuluj
        </BButton>
      </div>

      <div v-if="!showEditForm">
        <div class="info-row">
          <div class="label">
            Data wydruku
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            Rodzaj wydruku
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            Stan wydruku
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            Licznik kopii
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            Data anulowania
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            Stempel stanu
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            Typ
          </div>
          <div class="value">
            ?
            <CockpitStatusBadge
              v-if="false"
              label="TYPE"
              icon="fas fa-box"
              color="#F0F1F3"
              text-color="black"
            />
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            Pełna paleta
          </div>
          <div class="value">
            ?
          </div>
        </div>

        <div class="info-row">
          <div class="label">
            Ostatnia paleta
          </div>
          <div class="value">
            ?
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.edit-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 26px;
  color: #7F7F7F;
  background-color: #EFEFEF;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50px;
  font-weight: 500;

  &:hover {
    background-color: #D9D9D9;
  }
}

.info-row {
  display: flex;
  width: 100%;
  font-size: 12px;
  margin-bottom: 28px;
  font-weight: 500;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    width: 30%;
    color: #979797;
  }

  .value {
    width: 70%;
    color: #3F3F3F;

    input, select {
      width: calc(100% - 7rem);
      font-size: 11px;
      font-weight: 500;
      height: 18px;
      border: 1px solid #ECECEC;
      border-radius: 4px;
      padding: 0 8px;

      &.custom-select {
        padding: 0 8px;
      }
    }

    &.order-progress {
      width: 50%;
    }
  }
}

.action-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .action-button {
    padding: 4px 30px;
    font-size: 15px;

    i {
      margin-right: 4px;
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ECECEC;
  margin-bottom: 28px;
}
</style>
