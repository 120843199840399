<script>
import MarkingsTypeSelectButtons from '@/components/markings/MarkingsTypeSelectButtons.vue';
import MarkingsFilters from '@/components/markings/MarkingsFilters.vue';
import MarkingsList from '@/components/markings/list/MarkingsList.vue';
import CockpitOffcanvas from '@/components/cockpit/CockpitOffcanvas.vue';
import CockpitMatryoshkaItemContentsTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemContentsTab.vue';
import CockpitMatryoshkaItemDetailsTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemDetailsTab.vue';
import CockpitMatryoshkaItemLabelTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemLabelTab.vue';
import CockpitMatryoshkaItemHistoryTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemHistoryTab.vue';
import CockpitOffcanvasTypes from '@/components/cockpit/CockpitOffcanvasTypes';
import CockpitMatryoshkaItemActionButtons
from '@/components/cockpit/matryoshka/offcanvas/CockpitMatryoshkaItemActionButtons.vue';
import CockpitMatryoshkaItemAuditTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemAuditTab.vue';
import CockpitMatryoshkaItemOperationsTab
from '@/components/cockpit/matryoshka/offcanvas/tabs/CockpitMatryoshkaItemOperationsTab.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Cockpit',
  data: () => ({
    initLoading: false,
    selectedUnitId: 0,
    offcanvasVisible: false,
    offcanvasTabIndex: 0,
    pendingMarkingList: false,
    markingsList: [],
    filters: {
      searchQuery: '',
      orderId: null,
      status: null,
      lineId: null,
      skuId: null,
      startDate: null,
      endDate: null,
    },
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    selectedLabelArchiveId: null,
    CockpitOffcanvasTypes,
    fetchLabelArchiveDebounce: null,
  }),
  components: {
    CockpitMatryoshkaItemOperationsTab,
    CockpitMatryoshkaItemAuditTab,
    CockpitMatryoshkaItemActionButtons,
    CockpitMatryoshkaItemHistoryTab,
    CockpitMatryoshkaItemLabelTab,
    CockpitMatryoshkaItemDetailsTab,
    CockpitMatryoshkaItemContentsTab,
    CockpitOffcanvas,
    MarkingsList,
    MarkingsFilters,
    MarkingsTypeSelectButtons,
  },
  computed: {
    ...mapGetters([
      'globalSettings',
    ]),
    offcanvasTabs() {
      switch (this.offcanvasVisible) {
        case CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS:
          return [
            'Ogólne',
            'Zawartość',
            'Historia',
            'Etykieta',
          ];
        case CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT:
          return [
            'Audyt',
          ];
        case CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS:
          return [
            'Operacje',
          ];
        default:
          return [];
      }
    },
    offcanvasTitle() {
      switch (this.offcanvasVisible) {
        case CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS:
          return 'Szczegóły';
        case CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT:
          return 'Audyt';
        case CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS:
          return 'Dostępne operacje';
        default:
          return '';
      }
    },
    compatibilitySerialNumbers() {
      return this.filters.searchQuery.split(',').map(s => s.trim());
    },
    isCompatibilityView() {
      return this.compatibilitySerialNumbers.length > 1;
    },
    offcanvasIcon() {
      return 'fa-info-circle';
    },
    fetchParams() {
      return {
        plantCode: this.globalSettings.plantCode,
        query: {
          serialNumber: this.filters.searchQuery,
          orderId: this.filters.orderId,
          skuId: this.filters.skuId,
          unitId: this.selectedUnitId,
          labelStatus: this.filters.status,
          from: this.filters.startDate,
          to: this.filters.endDate,
          skip: (this.currentPage - 1) * this.perPage,
          take: this.perPage,
        },
      };
    },
  },
  watch: {
    filters: {
      handler() {
        this.currentPage = 1;
      },
      deep: true,
    },
    fetchParams: {
      handler() {
        this.fetchLabelArchive();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('labelArchive', [
      'getLabelArchive',
    ]),
    ...mapActions('packingSettings', [
      'getUnits',
      'getPackingSettings',
    ]),
    async fetchLabelArchive() {
      const { plantCode } = this.globalSettings;
      if (!plantCode) return;
      this.pendingMarkingList = true;

      if (this.fetchLabelArchiveDebounce) {
        clearTimeout(this.fetchLabelArchiveDebounce);
      }

      this.fetchLabelArchiveDebounce = setTimeout(async () => {
        if (this.isCompatibilityView) {
          let list = [];

          await Promise.all(this.compatibilitySerialNumbers
            .filter(n => n)
            .map(async serialNumber => {
              const { data } = await this.getLabelArchive({
                params: {
                  plantCode,
                  query: {
                    serialNumber,
                    unitId: this.selectedUnitId,
                    orderId: this.filters.orderId,
                    skuId: this.filters.skuId,
                    labelStatus: this.filters.status,
                    from: this.filters.startDate,
                    to: this.filters.endDate,
                    skip: (this.currentPage - 1) * this.perPage,
                    take: 10000,
                  },
                },
              });
              list = list.concat(data.items);
            }));

          this.markingsList = list
            .filter((v, i) => list.findIndex(t => (t.id === v.id)) === i);
        } else {
          const { data } = await this.getLabelArchive({
            params: {
              plantCode,
              query: {
                serialNumber: this.filters.searchQuery,
                unitId: this.selectedUnitId,
                orderId: this.filters.orderId,
                skuId: this.filters.skuId,
                labelStatus: this.filters.status,
                from: this.filters.startDate,
                to: this.filters.endDate,
                skip: (this.currentPage - 1) * this.perPage,
                take: this.perPage,
              },
            },
          });

          this.markingsList = data.items;
          this.totalRows = data?.totalCount || 0;
        }
        this.pendingMarkingList = false;
      }, 500);
    },
    handleSelectItem(id) {
      this.selectedLabelArchiveId = id;
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS;
    },
    handleAuditButtonClicked() {
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT;
    },
    handleBackButtonClicked() {
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS;
    },
    handleOpenOperations() {
      this.offcanvasVisible = CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS;
    },
  },
  async created() {
    const { plantCode } = this.globalSettings;
    this.initLoading = true;
    await this.getUnits({
      params: {
        plantCode,
      },
    });
    await this.getPackingSettings({
      params: {
        plantCode,
      },
    });
    await this.fetchLabelArchive();
    this.initLoading = false;
  },
};
</script>

<template>
  <Loader v-if="initLoading" />
  <div v-else>
    <div class="markings-root">
      <MarkingsTypeSelectButtons
        v-model="selectedUnitId"
      />

      <div class="markings-card">
        <MarkingsFilters
          v-model="filters"
        />

        <div class="line" />

        <div class="list">
          <Loader
            v-if="pendingMarkingList"
            class="pt-5"
          />
          <MarkingsList
            v-else
            :list="markingsList"
            @select-item="handleSelectItem"
          />
        </div>

        <div class="list-pagination">
          <BPagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            size="sm"
            pills
            limit="5"
            first-number
            last-number
          />
        </div>
      </div>
    </div>

    <CockpitOffcanvas
      :show="!!offcanvasVisible"
      :tabs-key="offcanvasVisible || ''"
      :tab-index.sync="offcanvasTabIndex"
      :tabs="offcanvasTabs"
      :title="offcanvasTitle"
      :icon="offcanvasIcon"
      :show-audit-button="
        offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS"
      :show-back-button="
        offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
          || offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT
      "
      @audit-button-clicked="handleAuditButtonClicked"
      @back-button-clicked="handleBackButtonClicked"
      @update:show="offcanvasVisible = $event"
    >
      <template #default>
        <!--    MATRIOSZKA    -->
        <CockpitMatryoshkaItemDetailsTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 0
          "
          :label-archive-id="selectedLabelArchiveId"
        />
        <CockpitMatryoshkaItemContentsTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 1
          "
          :label-archive-id="selectedLabelArchiveId"
        />
        <CockpitMatryoshkaItemHistoryTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 2
          "
          :label-archive-id="selectedLabelArchiveId"
        />
        <CockpitMatryoshkaItemLabelTab
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 3
          "
          :label-archive-id="selectedLabelArchiveId"
        />

        <!--    MATRIOSZKA OPERATIONS    -->
        <CockpitMatryoshkaItemOperationsTab
          v-if="offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_OPERATIONS
            && offcanvasTabIndex === 0"
        />

        <!--    MATRIOSZKA AUDIT    -->
        <CockpitMatryoshkaItemAuditTab
          v-if="offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_AUDIT
            && offcanvasTabIndex === 0"
        />
      </template>

      <template #actions>
        <!--    MATRIOSZKA    -->
        <CockpitMatryoshkaItemActionButtons
          v-if="
            offcanvasVisible === CockpitOffcanvasTypes.QUEUE_ITEM_DETAILS
              && offcanvasTabIndex === 0
          "
          @open-operations="handleOpenOperations"
        />
      </template>
    </CockpitOffcanvas>
  </div>
</template>

<style scoped lang="scss">
.markings-card {
  background-color: white;
  height: calc(100vh - 90px - 1rem);
  overflow: hidden;
  margin-top: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 9px 9px 0 0;
  display: flex;
  flex-direction: column;

  .line {
    width: 100%;
    border-bottom: 1px solid #F0F1F3;
  }

  .list {
    margin-top: 1rem;
    flex: 1;
    overflow-y: auto;
    padding-right: 1rem;
  }

  .list-pagination {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    margin-bottom: -0.75rem;
    border-top: 1px solid #F0F1F3;
  }
}
</style>
