<script>
export default {
  name: 'CockpitMatryoshkaColumnQueueItem',
  props: {
    item: Object,
    palletsColumn: Boolean,
    palletStatus: String,
  },
  emits: [
    'select-item',
  ],
};
</script>

<template>
  <div
    v-if="palletsColumn"
    class="queue-item"
    @click.stop="$emit('select-item', item.id)"
  >
    <div class="left-box">
      <div v-if="false">
        <div
          class="pallets-badge"
          :class="{
            'pallets-badge--open': palletStatus === 'OTWARTA',
            'pallets-badge--closed': palletStatus === 'ZAKOŃCZONA',
          }"
        >
          <i
            v-if="palletStatus === 'OTWARTA'"
            class="fas fa-bolt-lightning"
          />
          <i
            v-if="palletStatus === 'ZAKOŃCZONA'"
            class="fas fa-check-circle"
          />

          {{ palletStatus }}
        </div>
      </div>

      {{ item.serialNumber }}
    </div>

    <div class="right-box">
      <i class="fas fa-box" />
      {{ item.containedLabels.length }}
    </div>
  </div>
  <div
    v-else
    class="queue-item"
    @click.stop="$emit('select-item', item.id)"
  >
    {{ item.serialNumber }}
  </div>
</template>

<style scoped lang="scss">
.queue-item {
  border-radius: 6px;
  padding: 15px 20px;
  background-color: white;
  border: 1px solid #F0F1F3;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:hover {
    background-color: #F0F1F3;
  }

  .left-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .right-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    margin: auto 0;
    color: #7E7E7E;

    i {
      font-size: 14px;
      margin-right: 5px;
    }
  }

  .pallets-badge {
    font-size: 10px;
    padding: 3px 10px;
    border-radius: 4px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;

    &--open {
      background-color: #5886C1;
    }

    &--closed {
      background-color: #7CB92D;
    }

    i {
      margin-right: 5px;
    }
  }
}
</style>
