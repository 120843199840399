<script>
import CockpitMatryoshkaInheritanceTree
from '@/components/cockpit/matryoshka/inheritance-tree/CockpitMatryoshkaInheritanceTree.vue';

export default {
  name: 'CockpitMatryoshkaItemContentsTab',
  props: {
    labelArchiveId: Number,
  },
  components: {
    CockpitMatryoshkaInheritanceTree,
  },
};
</script>

<template>
  <div>
    <CockpitMatryoshkaInheritanceTree
      :label-archive-id="labelArchiveId"
    />
  </div>
</template>

<style scoped lang="scss">

</style>
