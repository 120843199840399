<script>

import moment from 'moment/moment';
import { mapGetters, mapState } from 'vuex';
import MarkingStatusBadge from '@/components/markings/MarkingStatusBadge.vue';

export default {
  name: 'MarkingsListItem',
  props: {
    item: Object,
    hideActions: Boolean,
    disabled: Boolean,
  },
  components: { MarkingStatusBadge },
  emits: [
    'select-item',
  ],
  computed: {
    ...mapState({
      lines: state => state.lines.list || [],
      labelingPoints: state => state.labelingPoints.list || [],
    }),
    ...mapGetters('packingSettings', [
      'getHigherLevelUnit',
      'getLowerLevelUnit',
    ]),
    lineName() {
      return this.lines.find(l => l.id === this.item.lineId)?.name || '';
    },
    labelingPointName() {
      return this.labelingPoints.find(l => l.id === this.item.labelingPointId)?.name || '';
    },
    lowerLevelUnit() {
      return this.getLowerLevelUnit(
        this.item.unitId,
        this.item.skuId,
      );
    },
    upperLevelUnit() {
      return this.getHigherLevelUnit(
        this.item.unitId,
        this.item.skuId,
      );
    },
    itemParent() {
      if (!this.item.containers) return {};
      return this.item.containers
        .find(c => c.containerUnitId === this.upperLevelUnit?.id) || {};
    },
    itemChildren() {
      if (!this.item.containedLabels) return [];
      return this.item.containedLabels
        .filter(c => c.unitId === this.lowerLevelUnit?.id);
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('LLL');
    },
    handleClick() {
      if (this.disabled) return;
      this.$emit('select-item', this.item.id);
    },
  },
};
</script>

<template>
  <div
    class="markings-list-item"
    :class="{
      disabled,
    }"
    @click.stop="handleClick"
  >
    <div>
      <MarkingStatusBadge
        :status="item.labelStatus"
      />

      <div class="number">
        {{ item.serialNumber }}
      </div>
    </div>

    <div
      v-if="upperLevelUnit"
      class="parent-number"
    >
      <i class="fas fa-arrow-up-wide-short" />
      {{ itemParent.id ? '1' : '0' }}
    </div>

    <div
      v-if="lowerLevelUnit"
      class="parent-number"
    >
      <i class="fas fa-arrow-down-wide-short" />
      {{ itemChildren.length }}
    </div>

    <div class="date">
      <i class="far fa-calendar" />

      {{ formatDate(item.created) }}
    </div>

    <div class="order-id">
      <span class="label">
        Order ID:
      </span>

      {{ item.orderId }}
    </div>

    <div class="line">
      {{ lineName }}

      <span class="subline-name">
        /
        {{ labelingPointName }}
      </span>
    </div>

    <div
      v-if="!hideActions"
      class="item-actions"
    >
      <div class="item-action">
        <i class="fas fa-unlink" />
      </div>

      <div class="item-action">
        <i class="fas fa-trash" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.markings-list-item {
  border: 1px solid #F0F1F3;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
  font-size: 13px;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 #EEEFF0;
  cursor: pointer;

  &:hover:not(.disabled) {
    background-color: #f8f8f8;
    border: 1px solid #7CB92D;
    box-shadow: 0 1px 2px 0 rgba(#7CB92D, 0.25);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .number {
    font-weight: 500;
  }

  .children-numbers, .parent-number {
    font-weight: 500;

    i {
      margin-right: 4px;
    }
  }

  .date {
    font-size: 11px;
    color: #8A8A8A;

    i {
      margin-right: 2px;
    }
  }

  .order-id {
    font-size: 11px;
    color: #7E7E7E;

    .label {
      font-weight: 500;
    }
  }

  .line {
    font-size: 13px;
    color: black;
    font-weight: 500;

    .subline-name {
      font-size: 11px;
      font-weight: 400;
      color: #7E7E7E;
    }
  }

  .item-actions {
    display: flex;
    gap: 12px;

    .item-action {
      width: 32px;
      height: 32px;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      color: #585858;
      background-color: #EFEFEF;
      cursor: pointer;

      &:hover {
        background-color: #E0E0E0;
      }
    }
  }
}
</style>
